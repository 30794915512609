:root {
  --ion-color-dirty: #804000;
  --ion-color-dirty-rgb: 128, 64, 0;
  --ion-color-dirty-contrast: #ffffff;
  --ion-color-dirty-contrast-rgb: 255, 255, 255;
  --ion-color-dirty-shade: #713800;
  --ion-color-dirty-tint: #8d531a;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
}

/*
 * Optional CSS
 * -----------------------------------
 */

/* This sets a different background and item background in light mode on ios */
.ios body {
  --ion-background-color: #f2f2f6;
  --ion-toolbar-background: var(--ion-background-color);
  --ion-item-background: #fff;
}

/* This sets a different background and item background in light mode on md */
.md body {
  --ion-background-color: #f9f9f9;
  --ion-toolbar-background: var(--ion-background-color);
  --ion-item-background: #fff;
}

/* This is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}



body.camera-active {
  --ion-background-color: transparent;
  --ion-item-background: transparent;

  app-tabs {
    display: block;
    position: unset;
  }

  app-gallery {
    display: none;
  }

  ion-modal {
    --backdrop-opacity: 0;
  }
}

.ion-color-dirty {
  --ion-color-base: var(--ion-color-dirty);
  --ion-color-base-rgb: var(--ion-color-dirty-rgb);
  --ion-color-contrast: var(--ion-color-dirty-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dirty-contrast-rgb);
  --ion-color-shade: var(--ion-color-dirty-shade);
  --ion-color-tint: var(--ion-color-dirty-tint);
}

ion-modal.photos-modal {
  --width: 100%;
  --height: 100%;
  --border-radius: 5px;
}

ion-modal.filter-modal {
  --min-width: 40%;
  --max-height: 100%;
  --min-height: 80%;
}

.filter-hide {
  display: none !important;
}

.ios ion-title {
  font-size: 1.3rem;
}

.swiper-pagination--custom {
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1100;
  display: block;
  transition: opacity .15s ease-in-out;
  color: #172b4d;
  background-color: #fff;
  border-color: #fff;
}

// .swiper-pagination--custom:hover {
//   opacity: 1;
// }
.swiper-button-prev,
.swiper-button-next {
  //It doesn't work   
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: 0;
  border-radius: 50%;
  background-color: hsla(0, 0%, 100%, .5);
  transform: translateY(-50%);
  font-size: 12px;
  transition: all .15s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  --swiper-navigation-size: 22px;
  --swiper-theme-color: #000;
  transition: opacity .15s ease-in-out;
}

.asset-toolbar ul li button {
  transition: all .2s cubic-bezier(.785, .135, .15, .86);
  padding: 2px 10px;
  color: #142334;
  line-height: 1;
  cursor: pointer
}

.asset-toolbar ul li button:hover {
  color: #0967d2 !important;
  transform: scale(1.1)
}

.swiper:hover .swiper-button-prev,
.swiper:hover .swiper-button-next,
.swiper:hover .swiper-pagination--custom {
  opacity: 1;
}

.swiper-button-disabled {
  opacity: 0 !important;
}

.swiper-pagination-bullet {
  background-color: #000000;
  opacity: 0.9;

}

.swiper-pagination-bullet-active {
  background-color: #0184ff;
}

.alert-edit {
  div.alert-message {
    color: red;
  }
}

.icon-color-black {
  color: #000;
}

.icon-color-white {
  color: #fff;
}

.no-ml {
  margin-left: 0;
}

.action-sheet-container .action-sheet-button.selected {
  background-color: #2dce89;
  color: #fff;
}

.action-sheet-container .text-green-500 .action-sheet-icon {
  color: #2dce89;
}

.action-sheet-container .text-yellow-500 .action-sheet-icon {
  color: #f7b924;
}

.action-sheet-container .text-red-500 .action-sheet-icon {
  color: #f5365c;
}

.action-sheet-container .text-blue-500 .action-sheet-icon {
  color: #3880ff;
}

.action-sheet-container .text-gray-500 .action-sheet-icon {
  color: #8898aa;
}

.action-sheet-container .text-gray-800 .action-sheet-icon {
  color: #4b6584;
}

.action-sheet-container .text-current-50 .action-sheet-icon0 {
  color: currentColor;
}

ion-button.gallery-button {
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 5px;
  --padding-end: 5px;

}

ion-button.document-view-options {
  --padding-top: 20px;
  --padding-bottom: 10px;
  --padding-start: 5px;
  --padding-end: 5px;
}